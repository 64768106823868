import { useEffect, useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";

import { Bank } from "./model/Bank";
import './BankTable.css';

const storageKey = "BankTable";
let isLoaded = false;

function BankTable() {
  const [originalBankArray, setOriginalBankArray] = useState<Bank[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch(`/bank.json`)
      .then(res => res.json())
      .then((bankArray: Bank[]) => {
        bankArray.forEach(b => b.memo = '');

        const storage = load();
        storage.bArray?.forEach(a => {
          const target = bankArray.find(bank => bank.id === a.id);
          if (target) {
            target.memo = a.memo;
          }
        });
        if (storage.search) {
          setSearch(storage.search);
        }

        setOriginalBankArray(bankArray);

        isLoaded = true;
      })
      .catch((e) => {
        const errorMsg = '情報を取得できません。24時間後に来てね。例外情報=' + e.toString();
        console.error(errorMsg);
        alert(errorMsg);
        return;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoaded) save();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalBankArray, search]);

  let bankArray = [...originalBankArray];

  bankArray = bankArray.filter((b) => {
    let s = search;
    s = toHalfWidth(s);
    s = kanaToHira(s);
    s = s.toLowerCase();

    return false
      || b.name.toLowerCase().includes(s)
      || b.kana.includes(s)
      || (b.cotra && 'ことら送金'.includes(s))
      || (b.jcoin && 'j-coin'.includes(s))
      || (b.cplus && 'coin+'.includes(s))
      || b.memo.toLowerCase().includes(s)
  });

  const data = { nodes: bankArray };

  const columns = [
    { label: '', renderCell: (bank: Bank) => bank.name },
    { label: 'ことら', sort: { sortKey: 'COTRASORT' }, renderCell: (bank: Bank) => bank.cotra ? "ことら" : "" },
    { label: 'J-Coin', sort: { sortKey: 'JCOINSORT' }, renderCell: (bank: Bank) => bank.jcoin ? "J-Coin" : "" },
    { label: 'COIN+', sort: { sortKey: 'CPLUSSORT' }, renderCell: (bank: Bank) => bank.cplus ? "COIN+" : "" },
    {
      label: 'メモ', sort: { sortKey: 'MEMOSORT' }, renderCell: (bank: Bank) =>
        <input
          value={bank.memo}
          style={{ width: "100%", fontSize: "1rem", border: "none", padding: "0.1rem", margin: 0 }}
          onChange={(event) => {
            setOriginalBankArray((preBankArray) => {
              const target: Bank | undefined = preBankArray.find(b => b.id === bank.id);
              target!.memo = event.target.value;
              return [...preBankArray];
            });
          }}
        />
    },
  ];

  const sort = useSort(
    data, {},
    {
      sortFns: {
        COTRASORT: (array) => array.sort((a, b) => b.cotra - a.cotra),
        JCOINSORT: (array) => array.sort((a, b) => b.jcoin - a.jcoin),
        CPLUSSORT: (array) => array.sort((a, b) => b.cplus - a.cplus),
        MEMOSORT: (array) => {
          const top = array.filter(b => 1 <= b.memo.length).sort((a, b) => a.memo.localeCompare(b.memo));
          const bottom = array.filter(b => 0 === b.memo.length);
          return [...top, ...bottom];
        },
      }
    }
  );

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns:25% repeat(3, minmax(0, 1fr)) 20%;
      `,
      HeaderRow: `
        .th {
          text-align: center;
        }
        .th > div > div {
          justify-content: center;
        }
      `,
      Row: `
        &:nth-of-type(odd) {
          background-color: #d2e9fc;
        }

        &:nth-of-type(even) {
          background-color: #eaf5fe;
        }
      `,
      BaseCell: `
        text-align: center;

        &:first-of-type {
          font-weight: bold;
        }
      `,
    },
  ]);

  return (
    <div className="bankTable">
      <div className='searchBox'>
        <input placeholder="検索ワード" value={search} onChange={(event) => setSearch(event.target.value)} />
      </div>
      <CompactTable data={data} columns={columns} sort={sort} theme={theme} layout={{ custom: true }} />
    </div>
  );

  function save() {
    const bArray = originalBankArray.filter(b => b.memo).map(b => ({ id: b.id, memo: b.memo }));
    const json = JSON.stringify({ bArray, search });
    localStorage.setItem(storageKey, json);
  }

  function load(): { bArray: { id: number, memo: string }[] | undefined, search: string | undefined } {

    const json = localStorage.getItem(storageKey);
    if (!json) {
      return { bArray: undefined, search: undefined };
    }

    const storage = JSON.parse(json);

    if (!storage) {
      return { bArray: undefined, search: undefined };
    }

    return storage;
  }
}





// https://qiita.com/spm84/items/4ea8c53ac3aafcd4d66c
// 全角 -> 半角 (英数字)
function toHalfWidth(str: string) {
  // 全角英数字を半角に変換
  str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  return str;
}
// カタカナ->ひらがな
function kanaToHira(str: string) {
  return str.replace(/[\u30a1-\u30f6]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0x60);
  });
}

export default BankTable;