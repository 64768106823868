import { Link } from 'react-router-dom';
import './Head.css';

function Head() {

    return (
        <div className='head'>
            <Link to="/app" className="sitename">送金無料倶楽部</Link>
            <span>無料で送金できる銀行・アプリを確認できます</span>
        </ div>
    );
}

export default Head;