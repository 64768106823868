import BankTable from './BankTable';

import './App.css';
import Head from './Head';
import { Link } from 'react-router-dom';

function App() {

  return (
    <>
      <Head />
      無料送金サービスの比較は<Link to="/hikaku">こちら</Link>
      <BankTable />
    </>
  );
}

export default App;