import Head from './Head';
import './Hikaku.css';

function App() {

  return (
    <div className="hikaku">
      <Head />
      <h1>無料送金サービス比較</h1>

      <table id='self'>
        <caption>自身へ送金</caption>
        <thead>
          <tr>
            <th scope="col">サービス</th>
            <th scope="col">送金手順</th>
            <th scope="col">送金制限</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <th scope="row">
              <img className='logo' src='image/cotra.png' alt='ことら送金' /><br />
              ことら送金
            </th>
            <td>
              <span className="bank">銀行A</span><br />
              <span className='allow'>↓</span>①送金<br />
              <span className="bank">銀行B</span>
            </td>
            <td>
              上限10万円/1件<br />
              <span className='sub'>他にも送信側サービスごとに制限あり</span>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <img className='logo' src='image/jcoin.png' alt='J-Coin Pay' /><br />
              J-Coin Pay
            </th>
            <td className='flow'>
              <span className="bank">銀行A</span><br />
              &nbsp;<span className='allow'>↘</span>①チャージ<br />
              &nbsp;&emsp;<span className='app'>J-Coin Pay</span><br />
              &nbsp;<span className='allow'>↙</span>②口座に戻す<br />
              <span className="bank">銀行B</span><br />
            </td>
            <td>
              上限50万円/日
            </td>
          </tr>
          <tr>
            <th scope="row">
              <img className='logo' src='image/airwallet.png' alt='エアウォレット' /><br />
              エアウォレット<br />
              (COIN+)
            </th>
            <td className='flow'>
              <span className="bank">銀行A</span><br />
              &nbsp;<span className='allow'>↘</span>①チャージ<br />
              &nbsp;&emsp;<span className='app'>AirWALLET</span><br />
              &nbsp;<span className='allow'>↙</span>②出金<span className='sub'>※時間かかる</span><br />
              <span className="bank">銀行B</span><br />
            </td>
            <td>
              上限30万円/日
            </td>
          </tr>
        </tbody>
      </table>

      <table id='another'>
        <caption>他者へ送金</caption>
        <thead>
          <tr>
            <th scope="col">サービス</th>
            <th scope="col">送金手順</th>
            <th scope="col">送金上限</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <th scope="row">
              <img className='logo' src='image/cotra.png' alt='ことら送金' /><br />
              ことら送金
            </th>
            <td className='flow'>
              <span className='bank'>銀行(自分)</span><br />
              <span className='allow'>↓</span> ①送金<br />
              <span className='bank'>銀行(相手)</span>
            </td>
            <td>
              上限10万円/1件<br />
              <span className='sub'>他にも送信側サービスごとに制限あり</span>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <img className='logo' src='image/jcoin.png' alt='J-Coin Pay' /><br />
              J-Coin Pay
            </th>
            <td className='flow'>
              <span className='bank'>銀行(自分)</span><br />
              &nbsp;<span className='allow'>↘</span>①チャージ<br />
              &nbsp;&emsp;<span className='app'>J-Coin Pay(自分)</span><br />
              &nbsp;&emsp;<span className='allow'>↓</span>②送金<br />
              &nbsp;&emsp;<span className='app'>J-Coin Pay(相手)</span><br />
              &nbsp;<span className='allow'>↙</span>③口座に戻す<br />
              <span className='bank'>銀行(相手)</span><br />
            </td>
            <td>
              上限25万円/日
            </td>
          </tr>
          <tr>
            <th scope="row">
              <img className='logo' src='image/airwallet.png' alt='エアウォレット' /><br />
              エアウォレット<br />
              (COIN+)
            </th>
            <td className='flow'>
              <span className='bank'>銀行(自分)</span><br />
              &nbsp;<span className='allow'>↘</span>①チャージ<br />
              &nbsp;&emsp;<span className='app'>AirWALLET(自分)</span><br />
              &nbsp;&emsp;<span className='allow'>↓</span> ②送金<br />
              &nbsp;&emsp;<span className='app'>AirWALLET(相手)</span><br />
              &nbsp;<span className='allow'>↙</span>②出金<span className='sub'>※時間かかる</span><br />
              <span className='bank'>銀行(相手)</span><br />
            </td>
            <td>
              上限30万円/日
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default App;